if ('define' in window) {
define("discourse/theme-20/discourse/components/versatile-banner-column", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(20);
  const themePrefix = key => `theme_translations.20.${key}`;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (or @icon @columnContent)}}
    <div class="{{@columnClass}} single-box">
      {{#if @icon}}
        <div class="icon">
          {{#if this.isHttpLink}}
            <img class="responsive-img" src={{@icon}} alt="Icon" />
          {{else}}
            {{d-icon @icon}}
          {{/if}}
        </div>
      {{/if}}
      {{#if @columnContent}}
        <div>
          {{html-safe @columnContent}}
        </div>
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": null,
    "block": "[[[41,[28,[37,1],[[30,1],[30,2]],null],[[[1,\"  \"],[10,0],[15,0,[29,[[30,3],\" single-box\"]]],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[10,0],[14,0,\"icon\"],[12],[1,\"\\n\"],[41,[30,0,[\"isHttpLink\"]],[[[1,\"          \"],[10,\"img\"],[14,0,\"responsive-img\"],[15,\"src\",[30,1]],[14,\"alt\",\"Icon\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[35,2],[[30,1]],null]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[41,[30,2],[[[1,\"      \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,3],[[30,2]],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@icon\",\"@columnContent\",\"@columnClass\"],false,[\"if\",\"or\",\"d-icon\",\"html-safe\"]]",
    "moduleName": "discourse/components/versatile-banner-column.hbs",
    "isStrictMode": false
  });
  class VersatileBannerColumn extends _component2.default {
    get isHttpLink() {
      return this.args.icon.startsWith("http");
    }
  }
  _exports.default = VersatileBannerColumn;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VersatileBannerColumn);
});
}
