if ('define' in window) {
define("discourse/theme-20/discourse/initializers/discourse-versatile-banner", ["exports", "discourse/lib/api", "../components/versatile-banner"], function (_exports, _api, _versatileBanner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(20);
  const themePrefix = key => `theme_translations.20.${key}`;
  var _default = _exports.default = (0, _api.apiInitializer)("1.15.0", api => {
    api.renderInOutlet(settings.plugin_outlet, _versatileBanner.default);
  });
});
}
