if ('define' in window) {
define("discourse/theme-20/discourse/components/versatile-banner", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/cookie", "discourse/lib/utilities", "discourse-common/lib/icon-library", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _cookie, _utilities, _iconLibrary, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(20);
  const themePrefix = key => `theme_translations.20.${key}`;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldShow}}
    {{#unless this.bannerClosed}}
      <div class="banner-box">
        <div class="container">
          <div class="button-container">
            {{#if (theme-setting "dismissible")}}
              <DButton
                @action={{this.closeBanner}}
                @translatedLabel={{theme-i18n "close.label"}}
                @translatedTitle={{theme-i18n "close.title"}}
                @icon="times"
                class="close"
              />
            {{/if}}
            {{#if (theme-setting "collapsible")}}
              <DButton
                @action={{this.toggleBanner}}
                @translatedLabel={{this.toggleLabel}}
                @translatedTitle={{theme-i18n "toggle.title"}}
                @icon={{this.toggleIcon}}
                class="toggle"
              />
            {{/if}}
          </div>
          <div class="section-header">
            {{html-safe (theme-setting "main_heading_content")}}
          </div>
          <div
            id="banner-content_wrap"
            class={{if
              (and (theme-setting "collapsible") this.bannerCollapsed)
              "--banner-collapsed"
            }}
          >
            <div class="row">
              {{#each this.columnData as |data|}}
                <VersatileBannerColumn
                  @columnContent={{data.content}}
                  @icon={{data.icon}}
                  @columnClass={{data.class}}
                />
              {{/each}}
            </div>
          </div>
        </div>
      </div>
    {{/unless}}
  {{/if}}
  */
  {
    "id": null,
    "block": "[[[41,[30,0,[\"shouldShow\"]],[[[41,[51,[30,0,[\"bannerClosed\"]]],[[[1,\"    \"],[10,0],[14,0,\"banner-box\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"button-container\"],[12],[1,\"\\n\"],[41,[28,[37,2],[20,\"dismissible\"],null],[[[1,\"            \"],[8,[39,3],[[24,0,\"close\"]],[[\"@action\",\"@translatedLabel\",\"@translatedTitle\",\"@icon\"],[[30,0,[\"closeBanner\"]],[28,[37,4],[20,\"close.label\"],null],[28,[37,4],[20,\"close.title\"],null],\"times\"]],null],[1,\"\\n\"]],[]],null],[41,[28,[37,2],[20,\"collapsible\"],null],[[[1,\"            \"],[8,[39,3],[[24,0,\"toggle\"]],[[\"@action\",\"@translatedLabel\",\"@translatedTitle\",\"@icon\"],[[30,0,[\"toggleBanner\"]],[30,0,[\"toggleLabel\"]],[28,[37,4],[20,\"toggle.title\"],null],[30,0,[\"toggleIcon\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"section-header\"],[12],[1,\"\\n          \"],[1,[28,[35,5],[[28,[37,2],[20,\"main_heading_content\"],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,1,\"banner-content_wrap\"],[15,0,[52,[28,[37,6],[[28,[37,2],[20,\"collapsible\"],null],[30,0,[\"bannerCollapsed\"]]],null],\"--banner-collapsed\"]],[12],[1,\"\\n          \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"columnData\"]]],null]],null],null,[[[1,\"              \"],[8,[39,9],null,[[\"@columnContent\",\"@icon\",\"@columnClass\"],[[30,1,[\"content\"]],[30,1,[\"icon\"]],[30,1,[\"class\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[\"data\"],false,[\"if\",\"unless\",\"theme-setting\",\"d-button\",\"theme-i18n\",\"html-safe\",\"and\",\"each\",\"-track-array\",\"versatile-banner-column\"]]",
    "moduleName": "discourse/components/versatile-banner.hbs",
    "isStrictMode": false
  });
  class VersatileBanner extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "bannerClosed", [_tracking.tracked], function () {
      return this.cookieClosed || false;
    }))();
    #bannerClosed = (() => (dt7948.i(this, "bannerClosed"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "bannerCollapsed", [_tracking.tracked], function () {
      return this.collapsedFromCookie !== null ? this.collapsedFromCookie : this.isDefaultCollapsed;
    }))();
    #bannerCollapsed = (() => (dt7948.i(this, "bannerCollapsed"), void 0))();
    cookieClosed = (() => (0, _cookie.default)("banner_closed"))();
    cookieCollapsed = (() => (0, _cookie.default)("banner_collapsed"))();
    isDefaultCollapsed = (() => settings.default_collapsed_state === "collapsed")();
    collapsedFromCookie = (() => this.cookieCollapsed ? JSON.parse(this.cookieCollapsed).collapsed : null)();
    columnData = (() => [{
      content: settings.first_column_content,
      class: "first_column",
      icon: (0, _iconLibrary.convertIconClass)(settings.first_column_icon)
    }, {
      content: settings.second_column_content,
      class: "second_column",
      icon: (0, _iconLibrary.convertIconClass)(settings.second_column_icon)
    }, {
      content: settings.third_column_content,
      class: "third_column",
      icon: (0, _iconLibrary.convertIconClass)(settings.third_column_icon)
    }, {
      content: settings.fourth_column_content,
      class: "fourth_column",
      icon: (0, _iconLibrary.convertIconClass)(settings.fourth_column_icon)
    }])();
    get cookieExpirationDate() {
      if (settings.cookie_lifespan === "none") {
        (0, _cookie.removeCookie)("banner_closed", {
          path: "/"
        });
        (0, _cookie.removeCookie)("banner_collapsed", {
          path: "/"
        });
      } else {
        return moment().add(1, settings.cookie_lifespan).toDate();
      }
    }
    get displayForUser() {
      return settings.show_for_members && this.currentUser || settings.show_for_anon && !this.currentUser;
    }
    get showOnRoute() {
      const path = this.router.currentURL;
      if (settings.display_on_homepage && this.router.currentRouteName === `discovery.${(0, _utilities.defaultHomepage)()}`) {
        return true;
      }
      if (settings.url_must_contain.length) {
        const allowedPaths = settings.url_must_contain.split("|");
        return allowedPaths.some(allowedPath => {
          if (allowedPath.slice(-1) === "*") {
            return path.indexOf(allowedPath.slice(0, -1)) === 0;
          }
          return path === allowedPath;
        });
      }
    }
    get shouldShow() {
      return this.displayForUser && this.showOnRoute;
    }
    get toggleLabel() {
      return this.bannerCollapsed ? _I18n.default.t(themePrefix("toggle.expand_label")) : _I18n.default.t(themePrefix("toggle.collapse_label"));
    }
    get toggleIcon() {
      return this.bannerCollapsed ? "chevron-down" : "chevron-up";
    }
    closeBanner() {
      this.bannerClosed = true;
      if (this.cookieExpirationDate) {
        const bannerState = {
          name: settings.cookie_name,
          closed: "true"
        };
        (0, _cookie.default)("banner_closed", JSON.stringify(bannerState), {
          expires: this.cookieExpirationDate,
          path: "/"
        });
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "closeBanner", [_object.action]))();
    toggleBanner() {
      this.bannerCollapsed = !this.bannerCollapsed;
      let bannerState = {
        name: settings.cookie_name,
        collapsed: this.bannerCollapsed
      };
      if (this.cookieExpirationDate) {
        if (this.cookieCollapsed) {
          bannerState = JSON.parse(this.cookieCollapsed);
          bannerState.collapsed = this.bannerCollapsed;
        }
      } else {
        bannerState.collapsed = this.bannerCollapsed;
      }
      (0, _cookie.default)("banner_collapsed", JSON.stringify(bannerState), {
        expires: this.cookieExpirationDate,
        path: "/"
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "toggleBanner", [_object.action]))();
  }
  _exports.default = VersatileBanner;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VersatileBanner);
});
}
